// Place the URL here with the /portal suffix.
// Ex.:`https://domain.com/portal`;
const backendUrl = `https://api-qaeportgm.grupomurchison.com`;

/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'domain.com',
  protocol: 'https',
};

export default {
  frontendUrl,
  backendUrl,
};
